import { useNavigate } from "react-router-dom";
import HeaderRegister from "../../Components/HeaderRegister";
import "./style.css";
import { routes } from "../../routes";
import { Fade } from "react-reveal";
import { useEffect } from "react";
export default function Status() {
  const Navigate = useNavigate();
  function Auth() {
    let formData = new FormData();
    formData.append("password", localStorage.password);
    formData.append("tel", localStorage.tel);

    fetch("https://gip.opora.digital/api/auth.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          let arr = data.split(",");
          localStorage.id = arr[0];

          localStorage.active = arr[1];
          localStorage.confirmRegister = arr[2];
          localStorage.name = arr[3];
          if (
            localStorage.active == "1" &&
            localStorage.confirmRegister == "1"
          ) {
            Navigate(routes.main);
          }
        }
      });
  }
  useEffect(() => {
    /*  if (localStorage.tel && localStorage.password) {
      Auth();
    } */
  }, []);
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      <HeaderRegister></HeaderRegister>
      <Fade right duration={500}>
        <div className="statusBody">
          <h3 className="titleReg">Ваш статус</h3>
          <div
            className="statusButton"
            onClick={() => Navigate(routes.startVideo)}
          >
            Я новый участник
          </div>
          <div
            className="statusButton"
            onClick={() => Navigate(routes.register)}
          >
            Я партнер
          </div>
          <p>Уже есть аккаунт?</p>
          <div className="statusButton" onClick={() => Navigate(routes.login)}>
            Войти
          </div>
        </div>
      </Fade>
    </div>
  );
}
