import "./App.css";
import { Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import "./fonts/stylesheet.css";
import { routes } from "./routes";
/* import Form from "./Pages/Form"; */
import Services from "./Pages/Services/Services";
import Team from "./Pages/Team";
import Objects from "./Pages/Objects";
import { useEffect, useState } from "react";
import { Url } from "./const";
import Thanks from "./Pages/Thanks";
import Reviews from "./Pages/Reviews";
import Form from "./Pages/Form";
import Status from "./Pages/Status";
import Auth from "./Pages/Auth";
import Register from "./Pages/Register/Register";
import Notification from "./Pages/Notification";
import VideoStart from "./Pages/VideoStart";
import VideoCont from "./Pages/VideoCont";
import Profile from "./Pages/Profile";
import Progress from "./Pages/Progress";
import Statistic from "./Pages/Statistic";
import Referal from "./Pages/Referal";
import Lead from "./Pages/Lead/Lead";
import Education from "./Pages/Education";
import ObjEm from "./Pages/ObjEm";
import Vebinars from "./Pages/Vebinars";
import Materials from "./Pages/Materials";
function App() {
  const [objects, setObjects] = useState();
  function getObj() {
    let formData = new FormData();
    formData.append("ID", "22");
    fetch(Url + "/getEscape.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setObjects(data);
      });
  }
  useEffect(() => {
    getObj();
    let tg = window.Telegram.WebApp;

    if (tg && tg.initDataUnsafe.user) {
      localStorage.idTg = tg.initDataUnsafe.user.id;

      localStorage.userTg = tg.initDataUnsafe.user.username;
    }
    var BackButton = tg.BackButton;
    BackButton.show();
    BackButton.onClick(function () {
      window.history.back();
    });
    tg.onEvent("backButtonClicked", function () {
      /* код */
    });
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Status></Status>} />
        <Route path={routes.login} element={<Auth />} />
        <Route
          path={routes.video1}
          element={
            <VideoCont video="ffffffffff" text="Кто мы? #1" link="/video2" />
          }
        />
        <Route
          path={routes.video2}
          element={
            <VideoCont
              video="ffffffffff"
              text="Почему Бали? #2"
              link="/video3"
            />
          }
        />
        <Route
          path={routes.video3}
          element={
            <VideoCont
              video="ffffffffff"
              text="Кейсы агентов? #3"
              link="/notification?text=Благодарим за просмотр!!&text1=Для получения доступа ко всем
              ресурсам зарегистрируйтесь&textButton=Регистрация"
              textButton="Регистрация"
            />
          }
        />
        <Route path={routes.register} element={<Register />} />
        <Route path={"/regnew"} element={<Register client={"new"} />} />
        <Route path={routes.profile} element={<Profile />} />
        <Route path={routes.main} element={<Main />} />
        <Route path={routes.statistic} element={<Statistic />} />
        <Route path={routes.progress} element={<Progress />} />
        <Route path={routes.startVideo} element={<VideoStart />} />
        <Route path={routes.form} element={<Form />} />
        <Route path={routes.lead} element={<Lead />} />
        <Route path={routes.vebinar} element={<Vebinars />} />
        <Route path={routes.material} element={<Materials />} />
        <Route path={routes.education} element={<Education />} />
        <Route path={routes.referal} element={<Referal />} />
        <Route path={routes.services} element={<Services />} />
        <Route path={routes.notification} element={<Notification />} />
        <Route path={routes.team} element={<Team />} />
        <Route path={routes.reviews} element={<Reviews />} />
        <Route path={routes.objEm} element={<ObjEm />} />
        <Route path={routes.thanks} element={<Thanks />} />
        <Route path={routes.objects} element={<Objects objects={objects} />} />
      </Routes>
    </div>
  );
}

export default App;
