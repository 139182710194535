import HeaderDark from "../../Components/HeaderDark";
import "./style.css";
import img1 from "./img/1.svg";
import img2 from "./img/2.svg";
import img3 from "./img/3.svg";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Profile() {
  const Navigate = useNavigate();
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      {" "}
      <Fade top duration={500}>
        <div className="videoHeader">
          <HeaderDark></HeaderDark>
          <h4> Личный кабинет</h4>
        </div>
      </Fade>
      <Fade left duration={500}>
        <div className="profileBody">
          <div onClick={() => Navigate(routes.progress)}>
            <img src={img1} alt="" />
            <p>Прогресс</p>
          </div>
          <div onClick={() => Navigate(routes.statistic)}>
            <img src={img2} alt="" />
            <p>Личная статистика</p>
          </div>
          <div onClick={() => Navigate(routes.referal)}>
            <img src={img3} alt="" />
            <p>Рефералы</p>
          </div>
        </div>
      </Fade>
    </div>
  );
}
