import icon1 from "./1.svg";
import icon2 from "./1.png";
import icon3 from "./3.svg";
import icon4 from "./4.svg";
import woman from "./g.gif";
import message from "./message.svg";
import logo from "./logo.png";
import back from "./back.png";
import HeadShake from "react-reveal/HeadShake";
import "./style.css";
import Typewriter from "typewriter-effect";
import { Fade, Flip } from "react-reveal";
import Zoom from "react-reveal/Zoom";

import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import cot from "./cot.svg";
export default function Main() {
  const Navigate = useNavigate();
  return (
    <div className="Main">
      <Fade top duration={500}>
        <div className="oval">
          <header>
            <img src={logo} alt="" className="logo" />

            <a className="send">
              <img src={message} alt="" />
              <p>Написать</p>
            </a>
          </header>
          {/*   <video width="100%" height="100%" no-controls loop autoPlay muted>
          <source src="./f2.mp4" type="video/mp4" />
        </video> */}
        </div>
      </Fade>
      <Fade right duration={500}>
        <div className="main_content">
          <div className="blocks">
            <div
              className="block"
              onClick={() => {
                Navigate(routes.education);
              }}
            >
              <img src={icon1} alt="" />

              <div>
                <h4>Обучение</h4>
                <p>и доп. материалы</p>
              </div>
            </div>

            <div
              className="block"
              onClick={() => {
                Navigate(routes.objects);
              }}
            >
              <img src={icon2} alt="" />

              <div>
                <h4>Каталог</h4>
                <p>база объектов</p>
              </div>
            </div>
          </div>
          {/* 
        <img src={cot} alt="" className="cotMain" /> */}

          <div className="blocks">
            <div
              className="block bl3"
              onClick={() => {
                Navigate(routes.profile);
              }}
            >
              <img src={icon3} alt="" />

              <div>
                <h4>Личный кабинет</h4>
                <p>и ваш прогресс</p>
              </div>
            </div>

            <div
              className="block bl4"
              onClick={() => {
                Navigate(routes.lead);
              }}
            >
              <img src={icon4} alt="" />

              <div>
                <h4>Передать лида</h4>
                <p>в работу</p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
