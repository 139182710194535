import { useNavigate } from "react-router-dom";
import HeaderRegister from "../../Components/HeaderRegister";
import "./style.css";
import { routes } from "../../routes";
import { useState } from "react";
import { Fade } from "react-reveal";
export default function Register({ client }) {
  const Navigate = useNavigate();
  const [form, setForm] = useState({
    tel: "",
    password: "",
    name: "",
    idOwn: "",
    client: "old",
    email: "",
    chatId: "",
    username: "",
    company: "",
  });
  const handleChange = (event) => {
    switch (event.target.name) {
      case "tel":
        setForm({
          ...form,
          tel: event.target.value,
        });
        break;
      case "password":
        setForm({
          ...form,
          password: event.target.value,
        });
        break;
      case "name":
        setForm({
          ...form,
          name: event.target.value,
        });
        break;
      case "company":
        setForm({
          ...form,
          company: event.target.value,
        });
        break;
      case "email":
        setForm({
          ...form,
          email: event.target.value,
        });
        break;
      case "idOwn":
        setForm({
          ...form,
          idOwn: event.target.value,
        });
        break;
    }
    console.log(form);
  };
  function Register() {
    let formData = new FormData();
    formData.append("FIO", form.name);
    formData.append("tel", form.tel);
    formData.append("email", form.email);
    formData.append("refer", form.idOwn);
    formData.append("password", form.password);
    formData.append("idTg", localStorage.idTg);
    formData.append("userTg", localStorage.userTg);
    if (client && client == "new") {
      formData.append("client", "new");
      fetch("https://gip.opora.digital/api/registrationUpdate.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data == "Ok") {
            Navigate(
              routes.notification +
                "?text=добро пожаловать!&text1= Напишите нашему менеджеру и мы откроем вам доступ ко всем нашим ресурсам."
            );
          }
        });
    } else {
      formData.append("client", form.client);
      fetch("https://gip.opora.digital/api/registration.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data == "Ok") {
            Navigate(
              routes.notification +
                "?text=добро пожаловать!&text1= Напишите нашему менеджеру и мы откроем вам доступ ко всем нашим ресурсам."
            );
          }
        });
    }
  }
  return (
    <div style={{ background: "#F2FEFF" }}>
      <HeaderRegister></HeaderRegister>
      <Fade left duration={500}>
        <div className="registerBody">
          <h3 className="titleReg">Регистрация</h3>
          <div className="inputBody">
            <p>Ваше ФИО*</p>
            <input
              name="name"
              value={form.name}
              onChange={(e) => handleChange(e)}
              type="text"
            />
          </div>
          <div className="inputBody">
            <p>Номер телефона*</p>
            <input
              name="tel"
              value={form.tel}
              onChange={(e) => handleChange(e)}
              type="tel"
            />
          </div>
          <div className="inputBody">
            <p>Пароль*</p>
            <input
              name="password"
              value={form.password}
              onChange={(e) => handleChange(e)}
              type="password"
            />
          </div>
          <div className="inputBody">
            <p>Ваша почта*</p>
            <input
              name="email"
              value={form.email}
              onChange={(e) => handleChange(e)}
              type="email"
            />
          </div>
          <div className="inputBody">
            <p>Компания</p>
            <input
              name="company"
              value={form.company}
              onChange={(e) => handleChange(e)}
              type="text"
            />
          </div>
          <div className="inputBody">
            <p>Код авторизации</p>
            <input
              name="idOwn"
              value={form.idOwn}
              onChange={(e) => handleChange(e)}
              type="text"
            />
          </div>

          <div
            style={
              form.tel.length < 7 ||
              form.name.length < 4 ||
              form.password.length < 6 ||
              form.email.length < 5
                ? { pointerEvents: "none", opacity: "0.6" }
                : {}
            }
            className="statusButton"
            onClick={() => Register()}
          >
            Зарегистрироваться
          </div>
        </div>
      </Fade>
    </div>
  );
}
