import { Fade } from "react-reveal";
import HeaderDark from "../../Components/HeaderDark";
import { useEffect, useState } from "react";
import YouTube from "react-youtube";
import "./style.css";
export default function Vebinars() {
  const [stocks, setStocks] = useState();
  function getStock() {
    let formData = new FormData();
    formData.append("ID", "26");
    fetch("https://app.opora.digital/getEscape.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }
  useEffect(() => {
    getStock();
  }, []);
  return (
    <div className="Vebinars">
      <Fade top duration={500}>
        <div className="videoHeader">
          <HeaderDark></HeaderDark>
          <h4>Вебинары</h4>
        </div>
      </Fade>
      <Fade left>
        <div className="vebBody">
          {stocks &&
            stocks.length > 0 &&
            stocks.map((em, index) => {
              if (em.hots == "Вебинар") {
                return (
                  <div key={index}>
                    <p>
                      <b>{index + 1}</b>
                      {em.title}
                    </p>
                    <YouTube
                      style={{ width: "100%" }}
                      className="videoW"
                      videoId={em.price.replace(
                        "https://www.youtube.com/watch?v=",
                        ""
                      )}
                    ></YouTube>
                  </div>
                );
              }
            })}
        </div>
      </Fade>
    </div>
  );
}
