import { useNavigate } from "react-router-dom";
import HeaderRegister from "../../Components/HeaderRegister";
import { routes } from "../../routes";
import { Fade } from "react-reveal";
import { useState } from "react";

export default function Lead() {
  const Navigate = useNavigate();
  const [form, setForm] = useState({
    tel: "",
    goal: "",
    name: "",
    id: "",
    info: "",
    price: "",
  });
  const handleChange = (event) => {
    switch (event.target.name) {
      case "tel":
        setForm({
          ...form,
          tel: event.target.value,
        });
        break;
      case "goal":
        setForm({
          ...form,
          goal: event.target.value,
        });
        break;
      case "name":
        setForm({
          ...form,
          name: event.target.value,
        });
        break;
      case "info":
        setForm({
          ...form,
          info: event.target.value,
        });
        break;
      case "price":
        setForm({
          ...form,
          price: event.target.value,
        });
        break;
    }
    console.log(form);
  };
  function addLead() {
    fetch("https://gip.opora.digital/olgagorkhiyan/add_lead.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Устанавливаем заголовок Content-Type: application/json
      },
      body: JSON.stringify({
        id_partner: localStorage.id,
        price: form.price,
        goal: form.goal,
        info: form.info,
        name: form.name,
        phone: form.tel,
      }),
    }).then((response) => {
      alert("Лид успешно отправлен");
      window.history.back();
    });
  }
  return (
    <div style={{ background: "#F2FEFF" }}>
      <HeaderRegister></HeaderRegister>
      <Fade left duration={500}>
        <div className="registerBody">
          <h3 className="titleReg">
            Заполните <br />
            форму
          </h3>
          <div className="inputBody">
            <p>Ваше имя*</p>
            <input
              name="name"
              value={form.name}
              onChange={(e) => handleChange(e)}
              type="text"
            />
          </div>
          <div className="inputBody">
            <p>Номер телефона*</p>
            <input
              value={form.tel}
              name="tel"
              onChange={(e) => handleChange(e)}
              type="tel"
            />
          </div>
          <div className="inputBody">
            <p>Цель покупки</p>
            <input
              name="goal"
              value={form.goal}
              onChange={(e) => handleChange(e)}
              type="text"
            />
          </div>
          <div className="inputBody">
            <p>Бюджет $</p>
            <input
              name="price"
              value={form.price}
              onChange={(e) => handleChange(e)}
              type="number"
            />
          </div>

          <div className="inputBody">
            <p>Доп. инфо</p>
            <input
              name="info"
              value={form.info}
              onChange={(e) => handleChange(e)}
              type="text"
            />
          </div>

          <div
            className="statusButton"
            style={
              form.name.length == 0 || form.tel.length == 0
                ? { pointerEvents: "none", opacity: "0.6" }
                : {}
            }
            onClick={() =>
              form.name.length > 0 && form.tel.length > 0 && addLead()
            }
          >
            Отправить
          </div>
        </div>
      </Fade>
    </div>
  );
}
