import { useNavigate } from "react-router-dom";
import HeaderDark from "../../Components/HeaderDark";
import { routes } from "../../routes";
import YouTube from "react-youtube";
import "./style.css";
export default function VideoCont({ text, link }) {
  const Navigate = useNavigate();
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
    },
  };
  return (
    <div>
      <div className="videoHeader">
        <HeaderDark></HeaderDark>
        <h4>
          Приятного <br />
          просмотра!
        </h4>
      </div>
      <div className="videoStartBody">
        <p className="boldTxt">{text}</p>
        <YouTube className="videoW"></YouTube>
        <span className="lif">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span>

        <div className="statusButton" onClick={() => Navigate(link)}>
          Следующее видео
        </div>
      </div>
    </div>
  );
}
