import { Zoom } from "react-reveal";
import logo from "./img/logo.png";
import message from "./img/message.svg";
import "./style.css";
import { useLocation } from "react-router-dom";
export default function HeaderDark() {
  const location = useLocation();
  function sendMessage() {
    let tg = window.Telegram.WebApp;

    let id = tg.initDataUnsafe.user.id;
    let txt = "Напишите ваш вопрос в чат:";
    let form = new FormData();
    form.append("text", txt);
    form.append("parse_mode", "html");

    fetch(
      "https://api.telegram.org/bot" +
        "6439538432:AAF9d3Cy5k4625B_1qX97NFHmVxkJ8Bh8V8" +
        "/sendMessage?chat_id=" +
        id,
      {
        method: "post",
        body: form,
      }
    ).then((response) => {
      tg.close();
    });
  }
  return (
    <div className="headerDark">
      <header>
        <img src={logo} alt="" className="logo" />

        <a
          onClick={() => sendMessage()}
          className="send"
          style={
            location.pathname == "/startVideo" ||
            location.pathname.includes("video") ||
            location.pathname.includes("notif")
              ? { opacity: "0.6", pointerEvents: "none" }
              : {}
          }
        >
          <img src={message} alt="" />
          <p>Написать</p>
        </a>
      </header>
      <div className="lineHead"></div>
    </div>
  );
}
