export const routes = {
  form: "/form",
  services: "/services",
  team: "/team",
  objects: "/objects",
  thanks: "/thanks",
  reviews: "/reviews",
  main: "/main",
  register: "/register",
  login: "/login",
  startVideo: "/startVideo",
  notification: "/notification",
  video1: "/video1",
  video2: "/video2",
  video3: "/video3",
  profile: "/profile",
  progress: "/progress",
  statistic: "/statistic",
  referal: "/referal",
  lead: "/lead",
  education: "/education",
  objEm: "/obj",
  material: "/material",
  vebinar: "/vebinar",
};
