import HeaderDark from "../../Components/HeaderDark";
import "./style.css";
import img1 from "./img/1.svg";
import img2 from "./img/2.svg";
import img3 from "./img/3.svg";
import tg from "./img/tg.svg";
import yt from "./img/yt.svg";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Education() {
  const Navigate = useNavigate();
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      <Fade top duration={500}>
        <div className="videoHeader">
          <HeaderDark></HeaderDark>
          <h4>Выберите раздел</h4>
        </div>
      </Fade>
      <Fade left duration={500}>
        <div className="profileBody">
          <div onClick={() => Navigate(routes.vebinar)}>
            <img src={img1} alt="" />
            <p>Вебинары и видео</p>
          </div>
          <div onClick={() => Navigate(routes.material)}>
            <img src={img2} alt="" />
            <p>Доп материалы</p>
          </div>
        </div>
        <p className="ggg">Подпишись на нас и получи еще больше пользы!</p>
        <div className="jj">
          <a href="https://t.me/+92J8UF25Xm84NmIy" target="_blank">
            <img src={tg} alt="" />
            <p>Telegram</p>
          </a>
          <a href="https://www.youtube.com/@invest-bali" target="_blank">
            <img src={yt} alt="" />
            <p>YouTube</p>
          </a>
        </div>
      </Fade>
    </div>
  );
}
