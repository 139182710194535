import HeaderDark from "../../Components/HeaderDark";
import "./style.css";

import t from "./img/t.svg";
import { Fade } from "react-reveal";
import { useEffect, useState } from "react";
export default function Progress() {
  const [balls, setBalls] = useState(0);
  const getBalls = () => {
    let form = new FormData();
    form.append("id", localStorage.idTg);
    fetch("https://app.opora.digital/bali/getBalls.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBalls(data[0].Balls);
      });
  };
  useEffect(() => {
    getBalls();
  }, []);
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      <div className="videoHeader">
        <HeaderDark></HeaderDark>
        <h4> Ваш прогресс</h4>
      </div>

      <Fade right duration={500}>
        <div className="progressBody">
          <div className="prog">
            <div>
              <div style={{ width: (Number(balls) / 1000) * 100 + "%" }}></div>
            </div>
            <p>
              <b>{balls}</b>
              <span>баллов</span>
            </p>
          </div>
          <img src={t} style={{ width: "100%" }} alt="" />
        </div>
      </Fade>
    </div>
  );
}
