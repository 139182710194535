import HeaderDark from "../../Components/HeaderDark";
import "./style.css";
import circle from "./img/circle.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Notification() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("text");
  const myParam2 = urlParams.get("text1");
  const myParam3 = urlParams.get("textButton");
  const Navigate = useNavigate();
  function sendMessage() {
    let tg = window.Telegram.WebApp;

    let id = tg.initDataUnsafe.user.id;
    let txt = "Напишите ваш вопрос в чат:";
    let form = new FormData();
    form.append("text", txt);
    form.append("parse_mode", "html");

    fetch(
      "https://api.telegram.org/bot" +
        "6439538432:AAF9d3Cy5k4625B_1qX97NFHmVxkJ8Bh8V8" +
        "/sendMessage?chat_id=" +
        id,
      {
        method: "post",
        body: form,
      }
    ).then((response) => {
      tg.close();
    });
  }
  return (
    <div className="notification">
      <HeaderDark></HeaderDark>
      <div className="notBody">
        <img src={circle} alt="" />
        <h4>{myParam}</h4>
        <div className="notLine"></div>
        <p>{myParam2}</p>
        <svg
          className="arrow"
          width="17"
          height="10"
          viewBox="0 0 17 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="white" stroke-width="2" />
        </svg>

        <div
          className="statusButton"
          onClick={() =>
            myParam3 == "Регистрация" ? Navigate("/regnew") : sendMessage()
          }
        >
          {myParam3 ? myParam3 : "Написать"}
        </div>
      </div>
    </div>
  );
}
