import HeaderDark from "../../Components/HeaderDark";
import "./style.css";

import t from "./img/t.svg";
import { Fade } from "react-reveal";
import { useEffect, useState } from "react";
export default function Statistic() {
  const [stat, setStat] = useState();
  function getRef() {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://gip.opora.digital/api/refer.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data;

        fetch("https://gip.opora.digital/olgagorkhiyan/get_stats.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Устанавливаем заголовок Content-Type: application/json
          },
          body: JSON.stringify({
            id_partner: localStorage.id,
            id_refer: arr,
          }), // Преобразуем JSON данные в строку перед отправкой
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setStat(data);
          });
      });
  }

  useEffect(() => {
    getRef();
  }, []);
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      <div className="videoHeader">
        <HeaderDark></HeaderDark>
        <h4> Личная статистика</h4>
      </div>
      <Fade right duration={500}>
        <div className="progressBody">
          <div className="statM">
            <div>
              <p>{stat && stat.count_leads}</p>
              <span>Сделки в работе</span>
            </div>
            <div>
              <p>{stat && stat.summ_comm + "$"}</p>
              <span>Ваша комиссия</span>
            </div>
            <div>
              <p>{stat && stat.summ_refer + "$"}</p>
              <span>Доход от рефералов</span>
            </div>
          </div>
          <div className="goals">
            {stat &&
              stat.list &&
              stat.list.length > 0 &&
              stat.list.map((em, index) => {
                return (
                  <div className="goalEm" key={index}>
                    <div>
                      <p>Название сделки</p>
                      <span>{em.name}</span>
                    </div>
                    <div>
                      <p>Сумма комиссии</p>
                      <span>{em.sum}</span>
                    </div>
                    <div>
                      <p>Этап сделки</p>
                      <span>{em.status}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Fade>
    </div>
  );
}
