import { useNavigate } from "react-router-dom";
import HeaderRegister from "../../Components/HeaderRegister";
import "./style.css";
import { routes } from "../../routes";
import { Fade } from "react-reveal";
import { useState } from "react";
export default function Auth() {
  const Navigate = useNavigate();
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  function Auth() {
    let formData = new FormData();
    formData.append("password", password);
    formData.append("tel", tel);

    fetch("https://gip.opora.digital/api/auth.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          let arr = data.split(",");
          localStorage.id = arr[0];
          localStorage.tel = tel;
          localStorage.password = password;
          localStorage.active = arr[1];
          localStorage.confirmRegister = arr[2];
          localStorage.name = arr[3];
          if (
            localStorage.active == "1" &&
            localStorage.confirmRegister == "1"
          ) {
            Navigate(routes.main);
          }
        }
      });
  }
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      <HeaderRegister></HeaderRegister>
      <Fade left duration={500}>
        <div className="authBody">
          <h3 className="titleReg">
            Добро <br /> пожаловать!
          </h3>
          <div className="inputBody">
            <p>Номер телефона</p>
            <input
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              type="tel"
            />
          </div>
          <div className="inputBody">
            <p>Пароль</p>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </div>
          <div
            style={
              tel.length < 6 || password.length < 6
                ? { pointerEvents: "none", opacity: "0.6" }
                : {}
            }
            className="statusButton"
            onClick={() => {
              Auth();
            }}
          >
            Войти
          </div>

          <div className="white-button">Регистрация</div>
        </div>
      </Fade>
    </div>
  );
}
