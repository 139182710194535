import HeaderDark from "../../Components/HeaderDark";
import "./style.css";
import videos from "./img/videos.svg";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import { useEffect } from "react";
export default function VideoStart() {
  const Navigate = useNavigate();
  const GetActivation = () => {
    let formData = new FormData();

    formData.append("userTg", localStorage.userTg);
    fetch("https://gip.opora.digital/api/getActivation.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {})
      .then((data) => {});
  };
  useEffect(() => {
    let formData = new FormData();

    formData.append("idTg", localStorage.idTg);
    formData.append("userTg", localStorage.userTg);
    fetch("https://gip.opora.digital/api/registrationNew.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data == 0) {
          localStorage.startDate = new Date();
        }
        if (localStorage.startDate) {
          let date1 = new Date(localStorage.startDate);
          let date2 = new Date();
          let summ = (date2 - date1) / (60 * 60 * 24 * 1000);
          alert(summ);
          if (summ > 0.001) {
            Navigate(
              routes.notification +
                "?text=Очень жаль, время вышло!!&text1= Напишите нашему менеджеру и мы откроем вам повторный доступ."
            );
          }
        }
      });
    if (!localStorage.startDate) {
      localStorage.startDate = new Date();
    }
  }, []);
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      <div className="videoHeader">
        <HeaderDark></HeaderDark>
        <h4> Добро пожаловать!</h4>
      </div>
      <Fade left duration={500}>
        <div className="videoStartBody">
          <p className="boldTxt">Вам доступно 3 видео.</p>
          <div className="videoLine"></div>
          <span className="lif">
            Чтобы получить доступ ко всем ресурсам, посмотрите все видео и
            зарегистрируйтесь.
          </span>
          <img src={videos} alt="" />
          <span className="lif">
            <b>У вас есть 24 часа</b> для просмотра до деактивации вашего
            аккаунта.
          </span>
          <div className="statusButton" onClick={() => Navigate(routes.video1)}>
            Смотреть видео
          </div>
        </div>
      </Fade>
    </div>
  );
}
