import { Zoom } from "react-reveal";
import logo from "./img/logo.png";
import message from "./img/message.svg";
import "./style.css";
export default function HeaderRegister() {
  return (
    <div>
      <header>
        <img src={logo} alt="" className="logo" />

        <a className="send" style={{ opacity: "0.6", pointerEvents: "none" }}>
          <img src={message} alt="" />
          <p>Написать</p>
        </a>
      </header>
      <div className="lineHead"></div>
    </div>
  );
}
