import { Slider } from "infinite-react-carousel";
import HeaderDark from "../../Components/HeaderDark";
import "./style.css";
export default function ObjEm() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = JSON.parse(urlParams.get("key"));
  let imgArr = myParam.Photo.split(" ");
  console.log(myParam);
  return (
    <div style={{ background: "#F2FEFF" }}>
      <div className="videoHeader">
        <HeaderDark></HeaderDark>
        <h4> {myParam.Title}</h4>
        <div className="sl">
          <Slider className="sld" dots arrows={false} duration={80}>
            {imgArr.map((em) => {
              return <img src={em} />;
            })}
          </Slider>
        </div>
        <div className="lo">
          <p>{myParam.Price + " $"} </p>
          <span>{myParam.brand}</span>
        </div>
        <div className="txt">{myParam.Texts}</div>
        <div className="a">
          <div
            className="statusButton"
            onClick={() => {
              window.open(myParam.PriceOld);
            }}
          >
            Презентация
          </div>
        </div>
      </div>
    </div>
  );
}
