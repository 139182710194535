import HeaderDark from "../../Components/HeaderDark";
import "./style.css";

import t from "./img/t.svg";
import { Fade } from "react-reveal";
import { useEffect, useRef, useState } from "react";
export default function Referal() {
  const [stat, setStat] = useState();
  const [countStat, setCountStat] = useState();
  const profileInputRef3 = useRef(null);
  function getStat() {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://gip.opora.digital/api/refer.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data;
        setCountStat(arr.length);
        fetch("https://gip.opora.digital/olgagorkhiyan/get_refer.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Устанавливаем заголовок Content-Type: application/json
          },
          body: JSON.stringify({
            id_partner: localStorage.id,
            id_refer: arr,
          }), // Преобразуем JSON данные в строку перед отправкой
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setStat(data);
          });
      });
  }
  const copytext3 = (e) => {
    profileInputRef3.current.select();
    document.execCommand("copy");
    alert("Приглашение скопировано!");
  };
  useEffect(() => {
    getStat();
  }, []);
  return (
    <div style={{ background: "#F2FEFF", minHeight: "100vh" }}>
      <div className="videoHeader">
        <HeaderDark></HeaderDark>
        <h4>Ваши рефералы</h4>
      </div>
      <Fade right duration={500}>
        <div className="progressBody">
          <div className="copy">
            <div className="copyId">
              Ваш ID-партнера* <b>{localStorage.id}</b>
            </div>
            <input
              readOnly
              ref={profileInputRef3}
              className="referal_link_input"
              value={`Хочу поделиться с тобой доступом к приложению Global Investment Property, который помогает мне зарабатывать на недвижимости Бали и совершенствовать риелторские навыки.\n\n   Ссылка на бота https://t.me/gip_bali_bot . Ты можешь зарегистрироваться по коду авторизации ${localStorage.id}`}
            ></input>
            <div className="button" onClick={copytext3}>
              Копировать
            </div>
          </div>
          <p className="suk">*Код авторизации в качестве реферальной ссылки</p>
          <div className="prop">
            <p>
              Количество <br />
              партнеров
            </p>
            <div>{countStat}</div>
          </div>
          <div className="prop">
            <p>
              Количество <br />
              их сделок
            </p>
            <div>{stat && stat.count_leads}</div>
          </div>
          <div className="prop">
            <p>
              Сумма их личного <br /> дохода
            </p>
            <div>{stat && stat.summ_refer + " $"}</div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
