import { Fade } from "react-reveal";
import HeaderDark from "../../Components/HeaderDark";
import { useEffect, useState } from "react";

import down from "./down.png";
import "./style.css";
export default function Materials() {
  const [stocks, setStocks] = useState();
  function getStock() {
    let formData = new FormData();
    formData.append("ID", "26");
    fetch("https://app.opora.digital/getEscape.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }
  useEffect(() => {
    getStock();
  }, []);
  return (
    <div className="Vebinars">
      <Fade top duration={500}>
        <div className="videoHeader">
          <HeaderDark></HeaderDark>
          <h4>Доп. материлы</h4>
        </div>
      </Fade>
      <Fade left>
        <div className="vebBody">
          {stocks &&
            stocks.length > 0 &&
            stocks.map((em, index) => {
              if (em.hots == "Доп") {
                return (
                  <a key={index} href={em.price} target="_blank">
                    <img src={down} alt="" />
                    <p>{em.title}</p>
                  </a>
                );
              }
            })}
        </div>
      </Fade>
    </div>
  );
}
