import "./style.css";
import gal from "./img/gal.svg";
import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import cat from "./img/cat.svg";
import { Fade, Zoom } from "react-reveal";
import HeaderDark from "../../Components/HeaderDark";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Question() {
  const [q1, setQ1] = useState(1);
  const [open, setOpen] = useState(false);
  const [objects, setObjects] = useState([]);
  const [priceMin, setPriceMin] = useState(0);
  const [choosenLoc, setChoosenLoc] = useState("Выберите район");
  const [priceMax, setPriceMax] = useState(100000000);
  const Navigate = useNavigate();
  const [locs, setLocs] = useState([]);
  const getLoc = () => {
    let form = new FormData();
    form.append("id", "26");
    fetch("https://app.opora.digital/cardsApi/getBrand.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLocs(data);
      });
  };
  useEffect(() => {
    let formData = new FormData();
    formData.append("id", "26");
    fetch("https://app.opora.digital/admin/Tovar.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setObjects(data);
      });
    getLoc();
  }, []);
  return (
    <div>
      <Fade top duration={500}>
        <div className="videoHeader">
          <HeaderDark></HeaderDark>
          <h4> База объектов</h4>
        </div>
      </Fade>
      <div className="objectsBody">
        <div className="priceDiv">
          <p>от</p>
          <input
            type="number"
            name=""
            id=""
            value={priceMin}
            onChange={(e) => setPriceMin(e.target.value)}
          />
          <p>до</p>
          <input
            type="number"
            name=""
            id=""
            value={priceMax}
            onChange={(e) => setPriceMax(e.target.value)}
          />
        </div>
        <div className="category" onClick={() => setOpen(!open)}>
          <p>
            {choosenLoc}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="2" fill="#126F81" />
              <path d="M7 10L12.5 15L18 10" stroke="white" stroke-width="2" />
            </svg>
          </p>
          {open && (
            <div className="categoryMap">
              {choosenLoc != "Выберите район" && (
                <div onClick={() => setChoosenLoc("Выберите район")}>Все</div>
              )}
              {locs &&
                locs.length > 0 &&
                locs.map((em, index) => {
                  if (em.brand != choosenLoc) {
                    return (
                      <div key={index} onClick={() => setChoosenLoc(em.brand)}>
                        {em.brand}
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </div>
        <div className="objMap">
          {objects &&
            objects.length > 0 &&
            objects.map((em, index) => {
              let img = em.Photo.split(" ");
              if (
                Number(em.Price) <= priceMax &&
                Number(em.Price) >= priceMin
              ) {
                if (choosenLoc == "Выберите район") {
                  return (
                    <div key={index}>
                      <img src={img[0]} alt="" />
                      <p>{em.Title}</p>
                      <span>Цена: от {em.Price} $</span>
                      <div
                        className="statusButton"
                        onClick={() => {
                          Navigate(routes.objEm + "?key=" + JSON.stringify(em));
                        }}
                      >
                        Смотреть объект
                      </div>
                    </div>
                  );
                } else {
                  if (em.brand == choosenLoc) {
                    return (
                      <div key={index}>
                        <img src={em.Photo} alt="" />
                        <p>{em.Title}</p>
                        <span>Цена: от {em.Price} $</span>
                        <div
                          className="statusButton"
                          onClick={() => {
                            Navigate(
                              routes.objEm + "?key=" + JSON.stringify(em)
                            );
                          }}
                        >
                          Смотреть объект
                        </div>
                      </div>
                    );
                  }
                }
              }
            })}
        </div>
      </div>
    </div>
  );
}
